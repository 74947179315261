import React, { useState } from 'react';
import trackprogress from "../assets/trackprogress.png";
import trackprogressbg1 from "../assets/trackprogressbg1.png";
import trackprogressBG from "../assets/trackprogressBG.png";
// import trackprogressbg2 from "../assets/trackprogressbg2.png";
import trackmobileImageBg from "../assets/trackmobileImageBg.png";
import CommonModal from "../Components/CommonModal";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
function TrackAppSection() {
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const customAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-150px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;
const customAnimation1= keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const customAnimationMain = keyframes`
 from { opacity: 0; }
  to { opacity: 1; }
`;
  return (
    <>
        <Reveal
    keyframes={customAnimationMain}
    triggerOnce={true}
    duration={3000}
    delay={500}
  >
    <div className="mainTrack">
      <img src={trackprogressBG} className="img-fluid trackImg2" alt="..." />
      <div className="container-fluid trackSection maxContain">
        <div className="row align-items-center">
        <img src={trackmobileImageBg} className="img-fluid trackmobileImg" alt="..." />
        <h1 className='mobileHeading'>
              Track Your Progress & <span>Achievements</span>
            </h1>
          <div className="col-lg-6 col-md-12 col-sm-12 col-12 trackSection1  d-flex  align-items-start justify-content-start">
          <Reveal keyframes={customAnimation} triggerOnce={true} duration={1800} delay={300}>
            <img src={trackprogress} className="img-fluid trackImg" alt="..." />
            </Reveal>
            <img src={trackprogressbg1} className="img-fluid trackImg1" alt="..." />
            {/* <img src={trackprogressbg2} className="img-fluid trackImg3" alt="..." /> */}
            <div className="trackImg3"></div>
            <div className="mobileShadow"></div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-12 text-start trackSection2">
            <h1>
              Track Your Progress & <span>Achievements</span>
            </h1>
            <p>
              Stay on top of your fitness goals with detailed analytics and
              progress tracking. Monitor your daily workouts, set achievable
              milestones, and visualize your journey with our advanced tools:
            </p>
            <ul>
            <Reveal keyframes={customAnimation1} triggerOnce={true} duration={2000} delay={500}>
              <li>
                <span className="trackSpan">Detailed Progress Reports:</span>{" "}
                Keep an eye on your performance with comprehensive weekly and
                monthly reports.
              </li>
              </Reveal>
              <Reveal keyframes={customAnimation1} triggerOnce={true} duration={2000} delay={1000}>
              <li>
                <span className="trackSpan">Goal Setting:</span> Set
                personalized fitness goals and track your progress in real time.
              </li>
              </Reveal>
              <Reveal keyframes={customAnimation1} triggerOnce={true} duration={2000} delay={1500}>
              <li>
                <span className="trackSpan">Milestones & Achievements:</span>{" "}
                Celebrate every success with badges and rewards as you reach new
                fitness milestones.
              </li>
              </Reveal>
              <Reveal keyframes={customAnimation1} triggerOnce={true} duration={2000} delay={2000}>
              <li>
                <span className="trackSpan">Insights & Analytics:</span> Get
                insights into your performance trends to adjust your routines
                and push yourself further.
              </li>
              </Reveal>
            </ul>
            <button className="btnCommon "  onClick={handleShow} type="submit">
              Download App
            </button>
          </div>
        </div>
      </div>
    </div>
    <CommonModal
      show={showModal}
      handleClose={handleClose}
      title="Important Update"
      body="We are updating our app with new features."
    />
    </Reveal>
    </>
  );
}

export default TrackAppSection;
