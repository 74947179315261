import React from "react";
import workout from "../assets/workout.png";
import tracking from "../assets/tracking.png"
import guidance from "../assets/guidance.png"
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";


const customAnimation = keyframes`
  30% { transform: scale(1.2); }
  40%, 60% { transform: rotate(-15deg) scale(1.2); }
  50% { transform: rotate(15deg) scale(1.2); }
  70% { transform: rotate(0deg) scale(1.2); }
  100% { transform: scale(1); }
`;
const customAnimation1 = keyframes`
  0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
`;
const customAnimationMain = keyframes`
 from { opacity: 0; }
  to { opacity: 1; }
`;

function FeatureSection() {
  return (
    <>
    <Reveal
    keyframes={customAnimationMain}
    triggerOnce={true}
    duration={3000}
    delay={500}
  >
    <div className="featureSection">
      <div className=" container  maxContain Feature ">
          <h1>Features <span>Overview</span></h1>
        <div className="FeatureRow ">
          <div className=" featureBox div1 ">
          <Reveal keyframes={customAnimation} triggerOnce={true} duration={1500} delay={200}>
            <img src={workout} className="img-fluid" loading="lazy" alt="..." />
          </Reveal>

            <h2>Personalized Workout </h2>
            <Reveal keyframes={customAnimation1} triggerOnce={true} duration={1500} delay={600}>
            <p>
              Tailored workout routines designed to fit your goals, whether
              you're aiming to build strength, lose weight, or improve
              endurance.
            </p>
            </Reveal>
            </div> 
          <div className=" featureBox featureSection2  div2 p-0">
          <Reveal keyframes={customAnimation} triggerOnce={true} duration={1500} delay={200}>
            <img src={tracking} className="img-fluid" alt="..." />
          </Reveal>
            <h2>Progress Tracking</h2>
            <Reveal keyframes={customAnimation1} triggerOnce={true} duration={1500} delay={1100}>
            <p>
              Easily track your workout history, measure your improvements, and
              set goals with our intuitive progress tracking dashboard.
            </p>
            </Reveal>
          </div>
          <div className=" featureBox featureSection3 div3 p-0">
          <Reveal keyframes={customAnimation} triggerOnce={true} duration={1500}  delay={200}>
            <img src={guidance} className="img-fluid" alt="..." />
            </Reveal>
            <h2>Nutrition Guidance</h2>
            <Reveal keyframes={customAnimation1} triggerOnce={true} duration={1500} delay={1600}>
            <p>
              Get access to personalized meal plans and nutrition tips to
              complement your workout regime and fuel your body the right way.
            </p>
            </Reveal>
          </div>
        </div>
      </div>
    </div>
    </Reveal>
    </>
  );
}

export default FeatureSection;
