import React from "react";
import footerImage from "../assets/siteLogo.png";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <div className="mainFooter">
      <div className="container maxContain1 footerSection ">
        <div className="row align-items-start p-0">
          <div className="col-lg-12 col-sm-12 footerColumn1 col-md-12 d-flex  align-items-center justify-content-between">
            <div className="d-flex align-items-center justify-content-center text-center footerImage">
              <a className="navbar-brand d-flex align-items-center" href="/">
                <img src={footerImage} className="img-fluid footerImg" alt="..." />
                <p className="m-0">Stakfit Muscles</p>
              </a>
            </div>
            <div className="svgIcon">
              <button className="btn" type="submit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26px"
                height="26px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="black"
                  d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4z"
                />
              </svg>
              </button>
              <button className="btn" type="submit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 24 24"
              >
                <path
                  fill="black"
                  d="M22 11.939a26.5 26.5 0 0 0-.41-4.801a2.5 2.5 0 0 0-1.803-1.714a61 61 0 0 0-7.81-.41c-2.609-.03-5.217.11-7.808.42a2.52 2.52 0 0 0-1.76 1.76a26.4 26.4 0 0 0-.408 4.8c-.01 1.61.127 3.216.409 4.8a2.56 2.56 0 0 0 1.78 1.782c2.592.303 5.2.44 7.81.409a59 59 0 0 0 7.787-.41a2.52 2.52 0 0 0 1.759-1.758c.307-1.608.46-3.242.454-4.878m-7.411.582l-4.005 2.271a.53.53 0 0 1-.785-.464V9.616a.52.52 0 0 1 .785-.454l4.137 2.378a.52.52 0 0 1-.022.908z"
                />
              </svg>
              </button>
              <button className="btn" type="submit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26px"
                height="26px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="black"
                  d="M4.75 1.875a2.125 2.125 0 1 0 0 4.25a2.125 2.125 0 0 0 0-4.25m-2 6A.125.125 0 0 0 2.625 8v13c0 .069.056.125.125.125h4A.125.125 0 0 0 6.875 21V8a.125.125 0 0 0-.125-.125zm6.5 0A.125.125 0 0 0 9.125 8v13c0 .069.056.125.125.125h4a.125.125 0 0 0 .125-.125v-7a1.875 1.875 0 1 1 3.75 0v7c0 .069.056.125.125.125h4a.125.125 0 0 0 .125-.125v-8.62c0-2.427-2.11-4.325-4.525-4.106a7.2 7.2 0 0 0-2.169.548l-1.306.56V8a.125.125 0 0 0-.125-.125z"
                />
              </svg>
              </button>
            </div>
          </div>
          <div className="col-lg-12 col-sm-12 col-md-12">
            <div className="footerLine"></div>
            <div className="footerPara ">
            <p className="">
              Copyright@2024 BytebridgeMuscleMAnia | All Rights Reversed
            </p>
            <div className="d-flex gap-1">

              <Link to="/privacy-policy" className="termsandConditions">Privacy Policy |</Link>
              <Link to="/terms-conditions" className="termsandConditions">Terms and Conditions</Link>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
