import React, { useEffect, useRef, useState } from "react";
import appstore from "../assets/appstore.png";
import googleplay from "../assets/googleplay.png";
import mobileImages from "../assets/mobileImages.png";
import bannerBackground1 from "../assets/bannerBackground1.png";
import bannerBackground2 from "../assets/bannerBackground2.png";
import bannerBackground3 from "../assets/bannerBackground3.png";
import bannerBackground4 from "../assets/bannerBackground4.png";
import bannerBackground5 from "../assets/bannerBackground5.png";
import mobilebannerBackground from "../assets/bannerBackgroundMobile.png";
import CommonModal from "../Components/CommonModal";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
function Banner() {
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const customAnimation = keyframes`
  0%{
    transform: scale(0.9);
  }
  50%{
    transform: scale(1);
  }
  100%{
    transform: scale(0.9);
  }
`;
const customAnimation1 = keyframes`
 0% { 
 transform: translateY(30px); 
 opacity: 0;
  }
  to { transform: translateY(0px);
   opacity: 1; }
`;
  return (
    <>
      <div className="headerSection mainBanner">
        <img
          src={bannerBackground1}
          className="img-fluid bannerImg bannerImg1"
          alt="..."
        />
        <img
          src={bannerBackground2}
          className="img-fluid bannerImg bannerImg2"
          alt="..."
        />
        <img
          src={bannerBackground3}
          className="img-fluid bannerImg bannerImg3"
          alt="..."
        />
        <img
          src={bannerBackground4}
          className="img-fluid bannerImg bannerImg4"
          alt="..."
        />
        <img
          src={bannerBackground5}
          className="img-fluid bannerImg bannerImg5"
          alt="..."
        />
        <img
          src={mobilebannerBackground}
          className="img-fluid mobilebannerImg"
          alt="..."
        />
        <div className="container maxContain bannerContainer">
          <div className="row align-items-center  BannerSection text-start">
            <div className="col-lg-6 col-md-12 col-sm-12 bannerSection1">
                <Reveal
                  keyframes={customAnimation1}
                  triggerOnce={true}
                  delay={300}
                  duration={1500}
                >
              <h1 className="m-0">
                Start Your{" "}
                <span>Fitness Journey</span> With Our App
              </h1>
              </Reveal>
              <Reveal
                  keyframes={customAnimation1}
                  triggerOnce={true}
                  delay={900}
                  duration={1500}
                >
              <p className="m-0">
                Track your progress, set goals, and achieve your fitness dreams
                with personalized workout plans and health insights.
              </p>
              </Reveal>
              <Reveal
                  keyframes={customAnimation1}
                  triggerOnce={true}
                  delay={1500}
                  duration={1500}
                >
              <div className="bannerImages">
                <img
                  src={googleplay}
                  onClick={handleShow}
                  className="img-fluid"
                  alt="..."
                  />
                <img
                  src={appstore}
                  onClick={handleShow}
                  className="img-fluid image2"
                  alt="..."
                  />
              </div>
                  </Reveal>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 bannerSection2">
              <Reveal
                keyframes={customAnimation}
                triggerOnce={true}
                duration={2000}
              >
                <img src={mobileImages} className="img-fluid" alt="..." />
              </Reveal>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <CommonModal
        show={showModal}
        handleClose={handleClose}
        title="Important Update"
        body="We are updating our app with new features."
      />
    </>
  );
}

export default Banner;
