import React, { useState } from 'react';
import brand from "../assets/siteLogo.png";
import CommonModal from '../Components/CommonModal';

function Header() {
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <>
      <div>
        <nav className="navbar navbar-light navbar-expand-lg headerSection">
          <div className="container maxContain justify-content-between p-0">
            <a className="navbar-brand d-flex align-items-center" href="/">
              <img src={brand} alt="site logo" />
              <h1 className=' m-0'>Stakfit Muscles</h1>
            </a>
            {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button> */}
            <div className=" justify-content-end" id="navbarSupportedContent">
              <button className="btnCommon btnConnect" onClick={handleShow} type="submit"><span>Download App</span></button>
            </div>
          </div>
        </nav>
      </div>
      {/* Modal */}
      <CommonModal
        show={showModal}
        handleClose={handleClose}
        title="Important Update"
        body="We are updating our app with new features."
      />
    </>
    
  )
}

export default Header
