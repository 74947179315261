import React from "react";

function Privacy() {
  return (
    <>
      <div className="container maxContain text-center">
        <div className="row align-items-center text-start TermSection">
          <div className="col-12">
            <h2>Effective Date: 16 Oct 2024</h2>
            <p>
              This Privacy Policy governs the collection, use, and disclosure of
              Personal Information by <b>Decrypt Block Metaverse Technologies Pvt
              Ltd,</b> its affiliates, and subsidiaries (“Stakfit Muscles”,
              “we”, “us”, “our”) in the context of the <b>Stakfit Muscles</b> app
              and its services. This Privacy Policy is incorporated by reference
              into the <b>Stakfit Muscles</b> End User License Agreement and Terms
              of Service (the “EULA”). All other terms not defined in this
              Privacy Policy will have the meanings set forth in the EULA.
            </p>
            <h1>Personal Information We Collect</h1>
            <p>
              “Personal Information” means any information relating to an
              identified or identifiable individual. We will inform you at the
              time of collection why you must provide certain Personal
              Information, and the consequences of not providing it. If you do
              not provide the necessary Personal Information, you may not be
              able to access some features of our app. We may collect Personal
              Information from various sources:
            </p>
            <ul>
              <li>
                <b>Personal Information provided upon registration:</b> When creating
                an account on <b>Stakfit Muscles,</b> you will provide details such
                as your first and last name, date of birth, city, country, email
                address, and password, along with payment information like your
                card number, CVV, expiration date, and billing address.
              </li>
              <li>
                <b>Social media credentials:</b> If you log in through Facebook,
                Instagram, or other social media platforms, we may collect
                public information from those platforms, such as your name and
                profile picture.
              </li>
              <li>
                <b>Content shared via the app:</b> We collect any content you upload,
                such as comments, progress photos, and other fitness-related
                data. This is used to tailor our services to your fitness goals.
              </li>
              <li>
                <b>Automatically collected data:</b> We use cookies and similar
                technologies to collect data like your IP address, device ID,
                actions on the app, and browsing patterns. You may manage
                cookies through your browser settings, but some app features may
                not work properly without them
              </li>
              <li>
                <b>Other sources:</b> We may also collect data from third parties, such
                as advertisers or service providers, to improve the
                functionality of <b>Stakfit Muscles.</b>
              </li>
            </ul>
            <p>
              By using our services, you authorize us to collect and retain your
              data in accordance with this policy.
            </p>
            <h1>How We Use Your Information</h1>
            <p>
              We use your Personal Information for purposes including, but not
              limited to:
            </p>
            <ul>
              <li>
                Providing, improving, and personalizing the <b>Stakfit Muscles</b>
                services.
              </li>
              <li>
                Analyzing user preferences and usage to offer tailored fitness
                recommendations and support.
              </li>
              <li>
                Managing your account, processing payments, and sending
                important account-related updates.
              </li>
              <li>
                Marketing communications, either from us or our trusted
                partners, based on your preferences.
              </li>
              <li>
                Compliance with legal obligations or protecting our app’s
                security and integrity.
              </li>
            </ul>
            <p>
              If you are located in the European Economic Area, we will rely on
              lawful legal grounds for processing your Personal Information,
              such as your consent or our legitimate business interests.
            </p>
            <h1>How We Share Your Information</h1>
            <p>
              We do not share your fitness progress photos with other users or
              third parties. However, we may share other Personal Information as
              follows:
            </p>
            <ul>
              <li>
                Service Providers: We may share data with third-party vendors
                and service providers who assist us in operating the app (e.g.,
                payment processors, data storage providers). These partners are
                required to keep your information confidential.
              </li>
              <li>
                Legal Requirements: We may disclose data to comply with legal
                obligations or in response to valid legal requests (e.g., court
                orders, subpoenas).
              </li>
              <li>
                Business Transfers: In the event of a merger, acquisition, or
                sale of assets, your data may be transferred as part of the
                transaction.
              </li>
            </ul>
            <h1>Your Rights and Choices</h1>
            <p>
              Depending on your location, you may have rights under applicable
              data protection laws, including:
            </p>
            <ul>
              <li>
                Accessing, correcting, or deleting your Personal Information.
              </li>
              <li>Withdrawing consent for the processing of your data.</li>
              <li>
                Opting out of personalized advertising or certain marketing
                communications.
              </li>
            </ul>
            <p>
              To exercise these rights, contact us at
              musclemaniafitness9@gmail.com. Some rights may be limited under
              local law.
            </p>
            <h1>Security of Your Information</h1>
            <p>
              We use industry-standard measures to protect your data, but no
              system can be 100% secure. You are responsible for keeping your
              password confidential. If there is a data breach, we will notify
              you in compliance with applicable laws.
            </p>
            <h1>Children's Privacy</h1>
            <p>
              Stakfit Muscles is not directed at children under 13, and we
              do not knowingly collect Personal Information from children. If
              you believe we have collected such information, please contact us
              at musclemaniafitness9@gmail.com so we can take corrective action.
            </p>
            <h1>Do Not Track</h1>
            <p>
              We do not currently respond to “Do Not Track” signals as there is
              no universally agreed-upon standard for how to handle such
              requests.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Privacy;
