import React from "react";

function Terms() {
  return (
    <>
      <div className="container maxContain text-center">
        <div className="row align-items-center text-start TermSection">
          <div className="col-12">
            <h2>END USER LICENSE AGREEMENT</h2>
            <h2>Last Updated: October 16, 2024</h2>
            <p>
              This End User License Agreement ("EULA") is a legal agreement
              between you ("End-User") and <b>Stakfit Muscles,</b> a fitness
              application owned and operated by <b>Musclemania Fitness,</b> located at
              <b> E 309, Sky Tower, Mohali, Punjab, India</b> (hereinafter referred to
              as "Licensor"). By downloading, installing, and using the Stakfit
              Musclemania application from the Apple App Store or Google Play
              Store, you agree to comply with and be bound by the terms and
              conditions of this License Agreement.
            </p>
            <h1>1. Acknowledgment</h1>
            <p>
              By accepting this EULA, you acknowledge that Apple and Google are
              not parties to this agreement and are not responsible for the
              application, its maintenance, or support. This agreement is solely
              between you and <b>Musclemania Fitness,</b> and not with Apple or Google.
            </p>
            <h1>2. License Grant</h1>
            <p>
              Subject to your compliance with this agreement, the Licensor
              grants you a non-exclusive, non-transferable, non-sublicensable
              license to install and use the <b>Stakfit Muscles</b> application
              ("Application") on devices that you own or control, as permitted
              by the App Store or Play Store Terms of Service. This license also
              applies to any updates or enhancements provided by Licensor,
              unless those updates come with a separate license, in which case
              the new license will apply.
            </p>
            <h1>3. License Restrictions</h1>
            <p></p>
            <ul>
              <li>
                Share, distribute, sell, rent, lease, or sublicense the
                Application to third parties.
              </li>
              <li>
                Reverse engineer, decompile, disassemble, or attempt to extract
                the source code of the Application.
              </li>
              <li>
                Modify, adapt, translate, or create derivative works based on
                the Application.
              </li>
              <li>
                Copy or reproduce the Application, except for backup purposes as
                allowed by this License.
              </li>
              <li>
                Remove, alter, or obscure any proprietary notices or labels on
                the Application.
              </li>
            </ul>
            <p>
              Violation of these terms may result in termination of your license
              and legal action.
            </p>
            <h1>4. Technical Requirements</h1>
            <ul>
              <li>
                The Application requires devices running a minimum firmware
                version of 1.0.0 or higher.
              </li>
              <li>
                The Licensor will provide updates to keep the Application
                functional with newer hardware and firmware, but you are not
                entitled to such updates by default.
              </li>
              <li>
                It is your responsibility to ensure that the device you use with
                the Application meets the required specifications.
              </li>
            </ul>
            <h1>5. Maintenance and Support</h1>
            <p>
              Licensor is responsible for maintaining and providing support for
              the Application. If you have any issues, please contact
              <b>Musclemania Fitness</b> at <b>musclemaniafitness9@gmail.com.</b>
            </p>
            <p>
              Apple and Google are not responsible for providing any maintenance
              or support services for the Application.
            </p>
            <h1>6. Use of Data</h1>
            <p>
              Licensor may access and collect personal information and data
              through your use of the Application in accordance with the Privacy
              Policy. You can view the full Privacy Policy on our website.
            </p>
            <h1>7. Liability</h1>
            <p>
              Licensor shall only be liable for intentional or gross negligence.
              In cases of slight negligence, Licensor shall only be liable for
              breaches of essential contractual obligations and limited to
              foreseeable damages typical of the contract. This does not apply
              to damages related to injury, life, or health.
            </p>
            <p>
              Licensor will not be responsible for any data loss caused by
              alterations or misuse of the Application.
            </p>
            <h1>8. Warranty</h1>
            <ul>
              <li>
                Licensor warrants that the Application is free from viruses,
                malware, or any harmful components at the time of download.
              </li>
              <li>
                The Application is provided "as is" without warranty of any
                kind. You acknowledge that the Application may not work
                flawlessly on all devices.
              </li>
              <li>
                If a defect is found within 90 days of purchase, you may report
                it to musclemaniafitness9@gmail.com, and Licensor will
                investigate and provide a solution or replacement at their
                discretion.
              </li>
              <li>
                If a defect is found within 90 days of purchase, you may report
                it to <b>musclemaniafitness9@gmail.com</b>, and Licensor will
                investigate and provide a solution or replacement at their
                discretion.
              </li>
              <li>
                If you purchased the Application via an App Store, any warranty
                claims should be addressed to the App Store operator, but
                liability will be limited to the refund of the purchase price.
              </li>
            </ul>
            <h1>9. Product Claims</h1>
            <p>
              Licensor, and not Apple or Google, is responsible for addressing
              claims related to the Application, including:
            </p>
            <ul>
              <li>Product liability claims.</li>
              <li>
                Claims of failure to comply with legal or regulatory
                requirements.
              </li>
              <li>Claims related to consumer protection or privacy laws.</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Terms;
