import { Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import './Style.scss';
import Terms from './Terms';
import Landing from './Landing';
import Privacy from './Privacy';

function App() {
  return (
    <div className="App">
      
    <Header/>
    <Routes>
      <Route path='/' exact Component={Landing} />
      <Route path='/terms-conditions' exact Component={Terms} />
      <Route path='/privacy-policy' exact Component={Privacy} />
    </Routes>
    <Footer/>
    </div>
  );
}

export default App;
