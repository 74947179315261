import React from 'react'
import Banner from './Banner'
import FeatureSection from './featureSection'
import TrackAppSection from './TrackAppSection'
function Landing() {
  return (
    <>
       <Banner/>
    <FeatureSection/>
    <TrackAppSection/>
    </>
  )
}

export default Landing
